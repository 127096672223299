/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Header3 = ({
  children, 
  align='left', 
  color='#FFFFFF', 
  fontFamily='Crimson Text',
  fontSize='2em', 
  fontWeight='300',
  spacing='45px',
}) => (
  <h3
    css={css`
      color: ${color};
      font-family: ${fontFamily};
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      margin: 0 0 ${spacing} 0;
      text-align: ${align};
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    `}
  >
    {children}
  </h3>
)

export default Header3